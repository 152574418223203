import $ from 'jquery';
import Module from '../lib/module';
import slick from 'slick-carousel/slick/slick';

@Module.define('components')
class Components extends Module {
  constructor(options) {
    super(options);
  }

  main() {
    this.imageSwap();
    this.swapItemSwap();
    this.imageDiff();
    this.showHeroVideo();
    this.heroSlider();
  }

  async bindEvents() {}

  imageSwap() {
    const $swapBtn = $('.js-image-swap-action');

    $swapBtn.on('click touch', e => {
      const $this = $(e.currentTarget);
      const swapItemIndex = $this.data('swap');
      const $relMainImage = $(`#image-swap-${swapItemIndex} .image-main img`);
      const curMainImageUrl = $relMainImage.attr('src');
      const altMainImageUrl = $relMainImage.data('img-alt');
      const $relSecondaryImage = $(`#image-swap-${swapItemIndex} .image-swap-control img`);
      const curSecondaryImageUrl = $relSecondaryImage.attr('src');
      const altSecondaryImageUrl = $relSecondaryImage.data('img-alt');

      $relMainImage.attr('src', altMainImageUrl).data('img-alt', curMainImageUrl);

      $relSecondaryImage.attr('src', altSecondaryImageUrl).data('img-alt', curSecondaryImageUrl);
    });
  }

  imageDiff() {
    $.fn.BeerSlider = function(options) {
      options = options || {};
      return this.each(function() {
        new BeerSlider(this, options);
      });
    };
    $('.beer-slider').each((index, el) => {
      $(el).BeerSlider({ start: $(el).data('beer-start') });
    });
  }

  swapItemSwap() {
    const $swapOption = $('.js-swap-option');

    $swapOption.on('click touch', e => {
      const $this = $(e.currentTarget);
      const swapItemIndex = $this.data('swap');
      const $swapItem = $('.js-image-swap-main');
      const $relSwapItem = $(`#image-swap-${swapItemIndex}`);

      $swapItem.removeClass('show');
      $relSwapItem.addClass('show');
    });
  }

  showHeroVideo() {
    window.onload = () => $('.js-hero-video').toggleClass('hidden');
  }

  heroSlider() {
    $('.js-hero-slider').slick({
      autoplay: true,
      autoplaySpeed: 7000,
      arrows: false,
      cssEase: 'ease-in-out',
      mobileFirst: true,
      speed: 500
    });
  }
}
