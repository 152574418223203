/* eslint-disable func-names */
import $ from 'jquery';
import { loadStripe } from '@stripe/stripe-js';
import Module from '../lib/module';

@Module.define('cart')
class Cart extends Module {
  constructor(options) {
    super(options);

    this.$cartAddBtn = $('body .js-cart-add');
    this.$productOptionSelect = $('.js-product-options');
    this.$cartCountElem = $('.js-cart-count');
    this.$checkbox = $('.js-quote-checkbox');
    this.$item = $('.js-product');
    this.$remove = $('.js-remove');
    this.$subtotalElem = $('.js-subtotal');
    this.$quantInput = $('.js-quant');
    this.stripePublishableKey = $('#shop').data('stripe-pk');
    this.taxExempt = false;
  }

  async main() {
    if (this.stripePublishableKey) {
      this.stripe = await loadStripe(this.stripePublishableKey);
    }
    this.setDefaultValues();
    this.calcSubtotal();
  }

  async bindEvents() {
    const self = this;
    $('.popup-modal').magnificPopup({
      type: 'inline',
      preloader: false,
      modal: true
    });
    $(document).on('click touch', '.popup-modal-dismiss', e => {
      e.preventDefault();
      self.taxExempt = false;
      $('#checkout-is-tax-exempt').removeClass('hidden');
      $('#checkout-tax-id').addClass('hidden');
      $.magnificPopup.close();
    });
    $('#tax-exempt-yes').on('click touch', e => {
      e.preventDefault();
      self.taxExempt = true;
      self.checkout();
    });
    $('#tax-exempt-no').on('click touch', e => {
      e.preventDefault();
      self.taxExempt = false;
      self.checkout();
    });
    $('.js-cart-add').on('click touch', e => {
      const $target = $(e.currentTarget);
      const callUrl = $target.data('call-url');
      const prodId = $target.attr('data-product-id');
      const cartType = $target.data('cart-type');
      this.addItem(callUrl, cartType, prodId);
    });
    this.$productOptionSelect.on('change', () => {
      this.updateButtonSkuid();
    });

    this.$checkbox.on('change', e => this.swapCarts(e));
    this.$quantInput.on('change', e => {
      this.updateItemQuantity(e);
    });
    this.$remove.on('click touch', e => {
      const $targetProduct = $(e.currentTarget).closest('.js-product');
      const cartId = $targetProduct.data('cart-item-id');
      this.removeItem(e, cartId);
      $targetProduct.remove();
      this.toggleNoItems();
    });

    $("input[type='number']").on('keydown', function(e) {
      if (['-', 'e', '+', 'E', '.'].includes(e.key)) {
        e.preventDefault();
      }
    });
  }

  checkout() {
    fetch('/cart/create-checkout-session', {
      method: 'POST',
      body: JSON.stringify({
        TaxExempt: this.taxExempt,
        TaxRegistrationId: $('#taxRegistrationId').val()
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(session => {
        return this.stripe.redirectToCheckout({ sessionId: session.id });
      });
  }

  addItem(url, type, id) {
    const self = this;
    $.ajax({
      method: 'POST',
      url: `${url}${id}`,
      success(data) {
        if (!data.Success) {
          if (data.Message) {
            self.showAlert('message', data.Message);
            return;
          }
          self.showAlert('error');
        } else {
          self.updateCartIcon();
          self.showAlert(type);
        }
      },
      error() {
        self.showAlert('error');
      }
    }).done(() => {});
  }

  removeItem(e, cartId) {
    e.preventDefault();
    const self = this;
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url: `/cart/removeitem/${cartId}`,
        success() {
          self.updateCartIcon();
          self.calcSubtotal();
          resolve();
        },
        error() {
          self.showAlert('error');
          reject();
        }
      });
    });
  }

  /**
   * sets default values on quantity inputs to revert to if any error occur while changing quantities
   */
  setDefaultValues() {
    const self = this;
    $('.js-quant').each(function() {
      self.setDefaultValue($(this));
    });
    this.toggleNoItems();
  }

  setDefaultValue($item) {
    const qty = $item.val();
    $item.data('init', qty);
  }

  updateItemQuantity(e) {
    const self = this;
    const $target = $(e.currentTarget);
    const quant = $target.val();
    const $item = $target.closest(this.$item);
    const cartId = $item.data('cart-item-id');
    const skuid = $item.data('product-id');
    const defaultValue = $target.data('init');
    const isSaleable = $item.closest('.js-product').hasClass('saleable');

    $.ajax({
      method: 'POST',
      url: `/cart/updateitemquantity/${cartId}`,
      data: {
        quantity: quant,
        skuid
      },
      success(data) {
        if (!data.Success) {
          if (data.Message) {
            self.showAlert('message', data.Message);
            $target.val(defaultValue);
            return;
          }
          self.showAlert('error');
        } else {
          self.setDefaultValue($target);
          self.updateCartIcon();
          self.calcSubtotal();
        }
      },
      error() {
        self.showAlert('error');
      }
    });
    if (!isSaleable && quant > 0) {
      const itemData = JSON.stringify($item.data('product-id'));
      $.ajax({
        method: 'POST',
        url: '/cart/movetoquote',
        data: {
          itemId: itemData
        },
        success(data) {
          if (!data.Success) {
            self.showAlert('error');
            return false;
          }
        },
        error() {
          self.showAlert('error');
        }
      });
    }
    this.toggleNoItems();
  }

  updateButtonSkuid() {
    const currOptionSkuid = this.$productOptionSelect.val();
    this.$cartAddBtn.attr('data-product-id', currOptionSkuid);
  }

  showAlert(type, message) {
    const $alert = $(`.js-${type}-alert`);
    if (type === 'message' && message !== undefined && message) {
      $alert.find('p').text(message);
    }
    $alert.fadeIn();

    setTimeout(() => {
      $alert.fadeOut();
    }, 2000);
  }

  toggleNoItems() {
    const $cart = $('body .cart');
    $cart.each(i => {
      let sum = 0;
      const { length } = $($cart[i]).find('.js-product');
      const quantities = $($cart[i])
        .find('.js-quant')
        .map((_, el) => Number(el.value))
        .get();
      $.each(quantities, function() {
        sum += parseFloat(this) || 0;
      });

      $($cart[i])
        .find('li.js-product.not-allowed')
        .each(function() {
          sum -= Number(
            $(this)
              .find('input.js-quant')
              .val()
          );
        });
      if (length < 1 || sum < 1) {
        $($cart[i])
          .find('.js-no-items')
          .removeClass('hide');
        $($cart[i])
          .find('.subtotal, .cart-submit, .quote-submit')
          .addClass('hide');
      } else {
        $($cart[i])
          .find('.js-no-items')
          .addClass('hide');
        $($cart[i])
          .find('.subtotal, .cart-submit, .quote-submit')
          .removeClass('hide');
      }
    });
  }

  updateCartIcon() {
    const self = this;
    $.ajax({
      method: 'GET',
      url: '/cart/count',
      success(total) {
        if (total < 10) {
          self.$cartCountElem.text(total);
        } else {
          self.$cartCountElem.text('9+');
        }
      },
      error() {
        self.showAlert('error');
      }
    });
  }

  logCurrentCart() {
    $.ajax({
      method: 'GET',
      url: `/cart/getcurrentcart`
    }).done(response => {
      console.log(response);
    });
  }

  swapCarts(e) {
    const self = this;
    const $currItem = $(e.currentTarget).closest('.js-product');
    if ($currItem.hasClass('saleable')) {
      $.ajax({
        method: 'POST',
        url: '/cart/movetoquote',
        data: {
          itemId: $currItem.data('product-id')
        },
        success(data) {
          if (!data.Success) {
            self.showAlert('error');
            e.currentTarget.checked = true;
            return false;
          }
          $currItem.appendTo('#quote .cart-list');
          $currItem.removeClass('saleable');
          self.toggleNoItems();
          self.calcSubtotal();
        },
        error() {
          self.showAlert('error');
        }
      });
    } else {
      $.ajax({
        method: 'POST',
        url: '/cart/movetocart',
        data: {
          itemId: $currItem.data('product-id')
        },
        success(data) {
          if (!data.Success) {
            self.showAlert('error');
            e.currentTarget.checked = true;
            return false;
          }
          $currItem.appendTo('#shop .cart-list');
          $currItem.addClass('saleable');
          self.toggleNoItems();
          self.calcSubtotal();
        },
        error() {
          self.showAlert('error');
        }
      });
    }
  }

  calcSubtotal() {
    let subtotal = 0;
    const $shopProduct = $('.cart#shop .js-product');

    $shopProduct.each(i => {
      const currProduct = $shopProduct[i];
      const productPrice = $(currProduct)
        .find('.js-price')
        .data('unit-price');
      const productQuant = $(currProduct)
        .find('.js-quant')
        .val();

      const productSubtotal = productPrice * productQuant;

      $(currProduct)
        .find('.js-price')
        .text(this.formatPrice(Number(productPrice)));
      // This code is creating a display bug in changing the price of the price/unit value.
      // This can be used later to add a total per product if needed. Leaving here in case.
      // $(currProduct)
      //   .find('.js-price')
      //   .text(this.formatPrice(productSubtotal));
      subtotal += +productSubtotal;

      this.$subtotalElem.text(this.formatPrice(subtotal));
    });
  }

  formatPrice(price) {
    const priceTrimmed = price.toFixed(2);
    const priceFormatted = priceTrimmed.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return priceFormatted;
  }
}
