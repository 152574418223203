/* eslint-disable func-names */

import $ from 'jquery';

import Module from '../lib/module';

@Module.define('product-specs')
class ProductSpecs extends Module {
  constructor(options) {
    super(options);
  }

  main() {}

  async bindEvents() {
    $('.js-toggle-specs').on('click touch', this.toggleSpecs);
    window.addEventListener('pageshow', this.productStateReset);
  }

  toggleSpecs() {
    $('.js-toggle-specs').toggleClass('active');

    $('.js-spec').toggleClass('hidden');
  }

  productStateReset() {
    const selectList = document.querySelector('.js-product-options');
    const cartButton = document.querySelector('.js-cart-add');
    const heroImage = document.querySelector('.js-primary-hero-image');
    const firstOption = selectList?.querySelector('option');
    const firstOptionValue = firstOption?.value;

    if (!firstOption || !firstOptionValue) {
      return;
    }

    const selectedVariantImage = firstOption.getAttribute('data-image-url');
    const heroImageLink = heroImage.querySelector('a');
    const heroImageImage = heroImage.querySelector('img');

    heroImageLink.setAttribute('href', selectedVariantImage);
    heroImageImage.setAttribute('src', selectedVariantImage);

    selectList.value = firstOptionValue;
    cartButton.setAttribute('data-product-id', firstOptionValue);
  }
}
