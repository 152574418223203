import Module from '../lib/module';

@Module.define('productVariant')
class ProductVariant extends Module {
  constructor(options) {
    super(options);

    this.heroImage = $('.js-primary-hero-image');
    this.variantSelect = $('.js-product-options');
  }

  main() {}

  async bindEvents() {
    this.variantSelect.on('change', () => this.variantSwitch());
  }

  variantSwitch() {
    const selectedVariantImage = this.variantSelect.find('option:selected').data('image-url');
    const $heroImageLink = this.heroImage.find('a');
    const $heroImageImage = this.heroImage.find('img');

    $heroImageLink.attr('href', selectedVariantImage);
    $heroImageImage.attr('src', selectedVariantImage);
  }
}
