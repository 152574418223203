import Module from '../lib/module';
import Util, { ViewPort } from '../lib/util';

@Module.define('common')
class Common extends Module {
  constructor(options) {
    super(options);

    this.videoControl = $('.js-video-control');
  }

  main() {}

  async bindEvents() {
    // smooth scroll
    $('a[href*="#"]')
      .not('[href="#"]')
      .not('.modal-inline')
      .not('[href="#0"]')
      .on('click', e => {
        this.linkHash(e);
      });

    $(window).on(
      'resize',
      Util.debounce(e => this.onResize(e))
    );

    this.videoControl.on('click touch', e => this.videoToggle(e));
    this.videoControl.on('keydown', e => {
      if (e.keyCode === 13) {
        this.videoToggle(e);
      }
    });
  }

  onResize(e) {}

  linkHash(e) {
    const target = e.currentTarget;

    // On-page links
    if (
      location.pathname.replace(/^\//, '') == target.pathname.replace(/^\//, '') &&
      location.hostname == target.hostname
    ) {
      // Figure out element to scroll to
      const hash = $(target.hash);
      this.smoothScrolling(e, hash);
    }
  }

  smoothScrolling(e, hash) {
    hash = hash.length ? hash : $(`[name=${this.hash.slice(1)}]`);

    // Does a scroll target exist?
    if (hash.length) {
      // Only prevent default if animation is actually gonna happen
      e.preventDefault();

      $('html, body').animate(
        {
          scrollTop: hash.offset().top - 80
        },
        1000,
        function() {
          // Callback after animation
          // Must change focus!
          var hash = $(hash);
          hash.focus();

          if (hash.is(':focus')) {
            // Checking if the target was focused
            return false;
          }
          hash.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
          hash.focus(); // Set focus again
        }
      );
    }
  }

  videoToggle(e) {
    const $video = $(e.currentTarget)
      .prev('video')
      .get(0);
    if ($video.paused) {
      $video.play();
      $(e.currentTarget)
        .find('span')
        .toggleClass('icon-pause icon-play');
    } else {
      $video.pause();
      $(e.currentTarget)
        .find('span')
        .toggleClass('icon-pause icon-play');
    }
  }
}
