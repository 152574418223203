/* eslint-disable func-names */
import $ from 'jquery';
import hoverintent from 'hoverintent';
import Module from '../lib/module';

@Module.define('nav-toggle')
class NavToggle extends Module {
  constructor(options) {
    super(options);

    this.SHOW_CLASS = '-show';
    this.navToggle = $('#nav-toggle');
    this.nav = $('#nav');
    this.navCategoriesSelector = $('.selector');
    this.navCategoryItems = $('.js-nav-dropdown');
    this.navSubCat = $('.js-nav-sub-categories-inner');
  }

  main() {
    this.toggleParentMenu();
    this.toggleSubMenu();
    this.onResize();
  }

  async bindEvents() {
    this.navToggle.on('click', () => this.nav.toggleClass(this.SHOW_CLASS));
  }

  onResize() {
    let windowWidth = $(window).width();

    $(window).resize(() => {
      if ($(window).width() !== windowWidth) {
        windowWidth = $(window).width();
        this.toggleSubMenu();
      }
    });
  }

  toggleParentMenu() {
    this.navCategoriesSelector.each((index, selector) => {
      $(selector).on('click', e => {
        e.preventDefault();

        $(selector).toggleClass('-active');
        const subCategory = $(selector)
          .parent()
          .parent()
          .find($(selector).data('active'))[0];
        if (subCategory === null) {
          return;
        }
        let expandingNavHeight = 0;
        if ($(subCategory).hasClass(this.SHOW_CLASS)) {
          $(subCategory).css('max-height', 0);
          $(subCategory).removeClass(this.SHOW_CLASS);
        } else {
          expandingNavHeight = subCategory.scrollHeight;
          $(subCategory).css('max-height', `${expandingNavHeight}px`);
          $(subCategory).addClass(this.SHOW_CLASS);
        }

        const alsoResize = $(selector).data('alsoResize');
        if (alsoResize !== undefined) {
          let parentToResize = null;
          let nextParent = $(selector).parent();
          const tries = 0;
          while (!parentToResize && tries < 20) {
            if ($(nextParent).hasClass(alsoResize.replace('.', ''))) {
              parentToResize = nextParent;
              break;
            }

            nextParent = nextParent.parentElement;
          }

          if (parentToResize) {
            const newHeight = parentToResize.scrollHeight + expandingNavHeight;
            $(parentToResize).css('max-height', `${newHeight}px`);
          }
        }
      });
    });
  }

  toggleSubMenu() {
    this.navCategoryItems.each((index, item) => {
      const subItem = $(item).find('.js-nav-sub-categories-inner')[0];
      if (this.navSubCat.attr('style')) {
        this.navSubCat.removeAttr('style');
        this.navSubCat.parent().css('max-height', '');
      }
      if (this.nav.hasClass(this.SHOW_CLASS)) {
        this.nav.removeClass(this.SHOW_CLASS);
      }

      if (subItem === null) {
        return;
      }

      const mobileNavMenu = $('#nav-toggle').is(':visible');

      const isMobile = mobileNavMenu;
      function hoverIn() {
        if (!isMobile) {
          $(subItem).slideDown(200, 'linear');
        }
      }

      function hoverOut() {
        if (!isMobile) {
          $(subItem).slideUp(200, 'linear');
        }
      }

      hoverintent(item, hoverIn, hoverOut).options({ timeout: 300 });
    });
  }
}
