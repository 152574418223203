/**
 * This file serves as the entry point to the client-side application logic
 * It bootstraps the Common Module when the page is finished loading
 */

import $ from './js/lib/jquery'; // Only need to import index.js because we expose it globally
import './js/lib/beer-slider';

import Module from './js/lib/module';
import Util from './js/lib/util';

import './js/modules/common';
import './js/modules/cart';
import './js/modules/components';
import './js/modules/nav-toggle';
import './js/modules/popups';
import './js/modules/product-specs';
import './js/modules/product-variant';
import './js/modules/scroll-reveal';
import './js/modules/sticky-nav';

global.$ = global.jQuery = window.$ = window.jQuery = $;

// make sure jquery exists
Util.ensureJQuery();

$().ready(() => Module.loadAll({ async: true }));
