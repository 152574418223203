import EventEmitter from 'events';

import Util from './util';

if (!global.__module) {
  global.__module_instances = new Map();
  global.__module_registry = new Map();

  global.__module = true;
}

const instances = window.__module_instances;
const registry = window.__module_registry;

const validateModuleOptions = (options = {}) => {
  const errors = [];

  if (!Util.hasValue(options.name)) errors.push('name is required');

  return {
    errors,
    success: errors.length === 0
  };
};

class Module extends EventEmitter {
  static define(options = {}) {
    return (target, key, descriptor) => {
      if (typeof target !== 'function' || !(target.prototype instanceof Module))
        throw new TypeError('target is not a subclass of Module');

      if (typeof options === 'string') {
        options = {
          name: options
        };
      }

      const validation = validateModuleOptions(options);
      if (!validation.success)
        throw new Error(`Invalid Module Definition: \n${validation.errors.join('\n')}`);

      registry.set(options.name, {
        options,
        module: target
      });
    };
  }

  async _bindEvents() {
    return this.bindEvents();
  }

  async bindEvents() {}

  static get(name) {
    if (instances.has(name)) return instances.get(name);

    if (registry.has(name))
      console.warn(`[Module] Module has ${name} registered, but an instance hasn't been loaded`);

    return undefined;
  }

  async _init() {
    await this._bindEvents();
    return this.init();
  }

  async init() {
    return true;
  }

  async load() {
    const intialization = await this._init();
    this.emit('init', intialization);

    // no await, make it async
    this._main();

    this.emit('loaded', Date.now());

    return this;
  }

  static async load(M, definition) {
    const instance = new M();
    instances.set(definition, instance);

    await instance.load();

    return instance;
  }

  static async loadAll(options = {}) {
    const async = !!options.async;
    for (const [name, item] of registry) {
      if (async) {
        Module.load(item.module, item.options);
      } else {
        await Module.load(item.module, item.options);
      }
    }
  }

  async _main() {
    return this.main();
  }

  async main() {}
}

export default Module;
