/* eslint-disable func-names */
import $ from 'jquery';
import 'magnific-popup';
import Module from '../lib/module';

@Module.define('popups')
class Popups extends Module {
  constructor(options) {
    super(options);

    this.selectors = ['.js-hero-image'];
  }

  main() {
    this.enablePopups();
  }

  async bindEvents() {}

  enablePopups() {
    this.selectors.forEach(selector => {
      const heroImages = $(selector);
      if ($(heroImages).length) {
        $(heroImages).magnificPopup({
          delegate: 'a',
          type: 'image',
          gallery: {
            enabled: true
          },
          showCloseBtn: false
        });
      }
    });
  }
}
