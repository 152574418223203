/* eslint-disable func-names */

import $ from 'jquery';

import util, { ViewPort } from '../lib/util';

import Module from '../lib/module';

@Module.define('sticky-nav')
class StickyNav extends Module {
  constructor(options) {
    super(options);
  }

  main() {
    this.stickyNav = $('.js-sticky-nav');

    this.navTop = this.stickyNav.offset()?.top;
  }

  async bindEvents() {
    $('.js-mobile-bar').on('click touch', this.mobileMenuToggle);

    $('.js-sticky-nav-menu a').on('click touch', () => {
      if (util.getViewPort() === ViewPort.Desktop) {
        this.blurStickyNav();
      } else {
        this.mobileMenuToggle();
      }
    });

    window.onscroll = () => this.toggleSticking();
  }

  mobileMenuToggle() {
    $('.js-sticky-nav-menu').toggleClass('active');
  }

  toggleSticking() {
    const isScrollPositionZero = window.scrollY === 0;

    if ($(this.stickyNav)) {
      if (isScrollPositionZero) {
        this.stickyNav.removeClass('sticky');
      } else if (window.pageYOffset >= this.navTop) {
        this.stickyNav.addClass('sticky');
      } else {
        this.stickyNav.removeClass('sticky');
      }
    }
  }

  blurStickyNav() {
    $('.js-sticky-nav-menu a').blur();
  }
}
