import 'setimmediate';

const fork = window.setImmediate || window.setTimeout;
const defer = window.requestIdleCallback || fork;

/**
 * Static class of utility functions, and the like.
 *
 * @namespace Util
 */
class Util {
  /**
   * Check the viewport type, based on the specified breakpoints
   *
   * @deprecated Use Util.getViewPort() instead.
   * @static
   * @param {number} [desktopBreakpoint=970]
   * @param {number} [tabletBreakpoint=750]
   * @returns {ViewPort} The viewport (desktop|mobile|tablet)
   * @memberof Util
   */
  static checkWidth(desktopBreakpoint, tabletBreakpoint) {
    // Util.deprecate('Util.checkWidth()', 'Use Util.getViewPort() instead')
    return Util.getViewPort(desktopBreakpoint, tabletBreakpoint);
  }

  /**
   * Debounce a function
   *
   * @static
   * @param {function} func
   * @param {number} [delay=150]
   * @param {boolean} [immediate=false]
   * @returns {function}
   * @memberof Util
   */
  static debounce(func, delay, immediate) {
    delay = delay || 150;
    immediate = immediate === true || false;

    let timeout;
    return function() {
      const context = this;
      const args = arguments;

      const later = function() {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };

      const callNow = immediate && !timeout;
      window.clearTimeout(timeout);
      timeout = window.setTimeout(later, delay);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  static async defer(fn) {
    return new Promise((resolve, reject) =>
      defer(() => {
        try {
          const result = fn();
          resolve(result);
        } catch (err) {
          reject(err);
        }
      })
    );
  }

  /**
   * Logs a depreciation notice to the console
   *
   * @static
   * @param {string|function} method The name of the method, or the method being deprecated
   * @param {string} [message=''] An additional message
   * @memberof Util
   */
  static deprecate(method, message) {
    message = message || '';
    let name = method;

    if (typeof method === 'function') {
      name = method.name;
    }

    console.warn(`[Deprecation] ${name}. ${message}`);
  }

  /**
   * Throws an error if `window.$` or `window.jQuery` is undefined
   *
   * @static
   * @memberof Util
   * @throws {ReferenceError}
   */
  static ensureJQuery() {
    if (typeof window.$ === 'undefined' || typeof window.jQuery === 'undefined')
      throw new ReferenceError('jQuery ($) is undefined. Please load jQuery before your bundle.');
  }

  /**
   * Set all elements in jquery-compatible selection to the height of the element in the collection with the largest height (within a row)
   *
   * @static
   * @param {string|JObject} selector
   * @memberof Util
   */
  static equalHeight(selector) {
    let currentTallest = 0;
    let currentRowStart = 0;
    const rowDivs = [];

    $(selector).each((i, el) => {
      const elem = $(el);
      elem.outerHeight('auto');
      const topPostion = elem.position().top;
      let currentDiv;

      if (currentRowStart !== topPostion) {
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
          rowDivs[currentDiv].outerHeight(currentTallest);
        }
        rowDivs.length = 0; // empty the array
        currentRowStart = topPostion;
        currentTallest = elem.outerHeight();
        rowDivs.push(elem);
      } else {
        rowDivs.push(elem);
        currentTallest = currentTallest < elem.outerHeight() ? elem.outerHeight() : currentTallest;
      }
      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        rowDivs[currentDiv].outerHeight(currentTallest);
      }
    });
  }

  /**
   * Set all elements within all selected classes to the height of the element with the largest height
   *
   * @static
   * @param {string|JObject} selector
   * @memberof Util
   */
  static equalHeightAll(selector) {
    let currentTallest = 0;
    const currentRowStart = 0;
    const rowDivs = [];

    $(selector).each((i, el) => {
      const elem = $(el);
      elem.outerHeight('auto');
      let currentDiv;

      rowDivs.push(elem);
      currentTallest = currentTallest < elem.outerHeight() ? elem.outerHeight() : currentTallest;

      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        rowDivs[currentDiv].outerHeight(currentTallest);
      }
    });
  }

  static async fork(fn) {
    return new Promise((resolve, reject) =>
      fork(() => {
        try {
          const result = fn();
          resolve(result);
        } catch (err) {
          reject(err);
        }
      })
    );
  }

  /**
   * Get the value for a named query string parameter
   *
   * @static
   * @param {string} name
   * @param {string} url
   * @returns {object}
   * @memberof Util
   */
  static getQueryString(name, url) {
    if (!Util.hasValue(url)) url = window.location.href;

    name = name.replace(/[\[\]]/g, '\\$&');

    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';
    const value = decodeURIComponent(results[2].replace(/\+/g, ' '));
    return value;
  }

  /**
   * Get the viewport type, based on the specified breakpoints
   *
   * @static
   * @param {number} [desktopBreakpoint=970]
   * @param {number} [tabletBreakpoint=750]
   * @returns {ViewPort} The viewport (desktop|mobile|tablet)
   * @memberof Util
   */
  static getViewPort(desktopBreakpoint, tabletBreakpoint) {
    desktopBreakpoint = desktopBreakpoint || 970;
    tabletBreakpoint = tabletBreakpoint || 750;

    const width = window.innerWidth;

    if (width >= desktopBreakpoint) {
      return ViewPort.Desktop;
    }
    if (width >= tabletBreakpoint) {
      return ViewPort.Tablet;
    }
    return ViewPort.Mobile;
  }

  /**
   * Determine if `value` is NOT null, or undefined, or if `value` is a string, is NOT empty
   *
   * @static
   * @param {any} value
   * @returns {boolean} A boolean indication if `value` is not undefined, null, or an empty string
   * @memberof Util
   */
  static hasValue(value) {
    let hasValue = value !== undefined && value != null;
    if (hasValue && typeof value === 'string') hasValue = hasValue && value !== '';

    return hasValue;
  }

  /**
   * Trim whitespace from both sides of a string, as well as occurences of the specified character
   *
   * @static
   * @param {string} str
   * @param {string} char
   * @returns {string} The trimmed string
   * @memberof Util
   */
  static trim(str, char) {
    if (str == null || str === undefined) return '';

    let value = str.trim();
    if (Util.hasValue(char)) value = value.replace(new RegExp(`(^${char}|${char}$)`, 'g'), '');

    return value;
  }
}

export default Util;

/**
 * @enum {string} ViewPort
 * @memberof Util
 * @example
 * import Util, { ViewPort } from './util';
 * console.log(Util.getViewPort() === ViewPort.Desktop)  // -> true
 */
const ViewPort = {
  Desktop: 'desktop',
  Mobile: 'mobile',
  Tablet: 'tablet'
};
export { ViewPort };
