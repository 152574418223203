/* eslint-disable func-names */
import $ from 'jquery';
import Module from '../lib/module';
import ScrollReveal from 'scrollreveal';

@Module.define('scroll-reveal')
class Scroll_Reveal extends Module {
  constructor(options) {
    super(options);

    this.scrollRevealElements = $('.js-scroll-reveal');
  }

  main() {
    this.initScrollReveal();
  }

  async bindEvents() {}

  initScrollReveal() {
    const options = {
      easing: 'cubic-bezier(0.6, 0.2, 0.1, 1)',
      scale: 0.5
    };
    // const scrollRevealElements = $('.js-scroll-reveal');
    if (this.scrollRevealElements.length > 0) {
      ScrollReveal().reveal(this.scrollRevealElements, options);
    }
  }
}
